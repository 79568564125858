import React from "react"
import t from "prop-types"
import styled from "@wigxel/react-components"
import { Loader } from "react-feather"
import { Button as _Button } from "@wigxel/react-components/lib/buttons"
import { theme, withProp } from "../style_helpers"

const Button = styled(_Button)`
  border-radius: 0;
  font-size: 16px;
  font-weight: bold;

  & + button {
    margin-left: 1rem;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:disabled {
    box-shadow: none;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    transform: translateY(-5px);
  }

  ${withProp("small", ` padding: .5rem 1rem; font-size: 13px; `)}

  .spinner {
    animation: spin 1s infinite forwards running 0s;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const ButtonPropTypes = {
  children: t.node.isRequired,
  className: t.string,
}

const PrimaryButton = styled(Button)`
  color: white;
  background-color: var(--primary-color);

  &:disabled {
    background-color: var(--color-7);
    color: var(--primary-color);
    opacity: 1;
  }
`

PrimaryButton.propTypes = ButtonPropTypes

Button.Primary = PrimaryButton

Button.Secondary = styled(Button)`
  background-color: ${theme("colors._7")};
  color: ${theme("colors.primary")};
`

Button.Ghost = styled(Button)`
  color: var(--primary-color);
  border: solid 1px var(--gray-200);

  &:focus {
    box-shadow: 0 0 0 3px rgba(206, 65, 52, 0.16);
  }

  &:hover,
  &:focus {
    background-color: var(--gray-200);
  }
`

Button.Danger = styled(Button.Ghost)`
  // background-color: ${theme("colors._4")}60;
  color: ${theme("colors._4")};
`

const SubmitButton = props => (
  <button {...props} className={`sub-btn +  ${props.className}`}>
    {props.children}
  </button>
)

SubmitButton.propTypes = ButtonPropTypes

Button.Submit = SubmitButton

Button.Loader = function LoadingButton({
  Button,
  loading,
  children,
  ...props
}) {
  return (
    <Button {...props}>
      <span>{children}</span>
      {loading && <Loader className="spinner ml-2 -mr-2" size={20} />}
    </Button>
  )
}

Button.Loader.propTypes = {
  Button: t.object.isRequired,
  loading: t.bool,
  children: t.node.isRequired,
}

export function navButtons(BaseButton, fn) {
  return [
    React.cloneElement(BaseButton, { onClick: fn(-1) }, "<   Back"),
    React.cloneElement(BaseButton, { onClick: fn(1) }, "Next   >"),
  ]
}

Button.Icon = function ButtonIcon({ text, Icon }) {
  return (
    <div className="flex items-center">
      <Icon className="inline-block" size={20} />
      <span className="mx-2">{text}</span>
    </div>
  )
}

Button.Minimal = function ButtonMinimal({ Icon, text, danger, ...props }) {
  let name = "primary"
  if (danger) name = "red-500"

  return (
    <button
      {...props}
      className={`
                opacity-75 py-2 transition-all transform ease-out duration-150 my-1 rounded-lg 
                outline-none hover:opacity-100 hover:text-${name} hover:translate-x-2
                focus:opacity-100 focus:text-${name} focus:translate-x-2
              	flex items-center appearance-none outline-none`}
    >
      <Button.Icon Icon={Icon} text={text} />
    </button>
  )
}

Button.Minimal.propTypes = Button.Icon.propTypes = {
  text: t.string.isRequired,
  Icon: t.object.isRequired, // React Element
}

export default Button
