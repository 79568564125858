import * as React from "react"
import { Link } from "gatsby"
import { Home, Calendar } from "react-feather"
import Button from "../components/Buttons"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="flex flex-col py-32 container mx-auto items-center">
        <h1 className="text-7xl font-black font-display">404: Not Found</h1>

        <div className="max-w-md mt-24">
          <p className="text-xl text-left">
            There&apos;s nothing <i className="font-bold">cooking</i> here at
            the moment. <br />
            Turns out you&apos;ve got yourself in a{" "}
            <i className="font-bold">chessy</i> situation.
          </p>

          <div className="flex justify-between w-full mt-12">
            <Link to="/">
              <Button primary>
                <Home className="mr-2" /> Go Home
              </Button>
            </Link>
            <Button ghost>
              <Calendar className="mr-4" />
              Upcoming Events
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
